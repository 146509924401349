<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link class="navbar-brand" to="/">
          <span class="brand-logo">
            <b-img :src="appLogoImage" alt="logo" />
          </span>
          <h2 class="brand-text mb-0">
            {{ isSupplier ? "Processor Portal" : appName }}
          </h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import { BImg, BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const userData = JSON.parse(localStorage.getItem("userData"));
    // const isSupplier = userData?.suppliers?.length;
    const isProcessor = userData?.processors?.length;
    console.log(isProcessor);
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      isProcessor,
      appName,
      appLogoImage,
    };
  },
};
</script>

<style>
</style>
